<!-- 模拟练习结果 -->
<template>
    <div class="testresult">
        <div class="resultbg"></div>
        <div class="resultwrap">
            <div class="answerInfo" v-if="result.abilityRate">
                <div class="count">
                    <div class="item_l">试题数量</div>
                    <div class="item_r">
                        <div>
                            {{answerNum}}/{{result.answerInfo.length}}<span>题</span>
                        </div>
                    </div>
                </div>
                <div class="time">
                    <div class="item_l">答题用时</div>
                    <div class="item_r">
                        <div v-if="result.answerLength<=60">
                            {{result.answerLength}}<span>秒</span>
                        </div>
                        <div v-else>
                            {{m}}<span>分</span>
                            {{s}}<span>秒</span>
                        </div>
                    </div>
                </div>
                <div class="score">
                    <div class="item_l">正确率</div>
                    <div class="item_r">
                        <div>
                            <!-- {{result.objectiveRightRate}}<span>%</span> -->
                            {{result.rightRate}}<span>%</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="power">
                <span>考察能力平均正确率统计</span>
            </div>
            <div class="detailInfo" v-if="result.abilityRate">
                <div class="detailItem" v-for="(item,index) in JSON.parse(result.abilityRate)" :key='index'>
                    <span>{{item.abilityName}}:</span>
                    <span>{{item.rate}}%</span>
                </div>
            </div>
            <div class="myTable" v-if="result.abilityRate">
                <el-table :data="result.answerInfo" style="width: 100%" :header-cell-style="{background:'#FAFBFF'}">
                    <el-table-column prop='sortNum' label="题目序号" align="center">
                    </el-table-column>
                    <el-table-column prop="answer" class-name="myColumn" label="你的答案" align="center">
                        <template slot-scope="scope">
                            <span style="minWidth:9px;display: inline-block;">{{scope.row.answer || '-'}}</span>
                            <img v-if="scope.row.isRight" class="isRight" src="../../assets/images/active/right.png" />
                            <img v-if="!scope.row.isRight && scope.row.questType!=5" class="isRight"
                                src="../../assets/images/active/error.png" />
                        </template>
                    </el-table-column>
                    <el-table-column prop="abilityName" label="考察能力" align="center">
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                result: {},
                answerNum: 0,
                m: null,
                s: null
            }
        },
        methods: {
            getResult() {
                let data = {
                    id: this.$route.query.historyId
                }
                this.$Api.Activity.testResult(data)
                    .then(res => {
                        console.log("获取结果成功", res)
                        this.result = res.data;
                        this.formattime(res.data.answerLength)
                        var i = 0;
                        let rightCount = 0; //正确的题的数量
                        let excpetFiveCount = 0; //刨除主观题以外的其他题目总数
                        res.data.answerInfo.map(item => {
                            if (item.answer) {
                                i += 1
                            }
                            if (item.isRight && item.questType != 5) {
                                rightCount++
                            }
                            if (item.questType != 5) {
                                excpetFiveCount++
                            }

                        })
                        // console.log(i);
                        this.answerNum = i;
                        this.$set(this.result, 'rightRate', ((rightCount / excpetFiveCount) * 100).toFixed(2))
                        console.log()
                        console.log('正确率', ((rightCount / excpetFiveCount) * 100).toFixed(2));
                    })
                    .catch(err => {
                        console.log('获取结果失败', err)
                    })
            },
            // 答题用时转换
            formattime(result) {
                var m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((
                    result / 60 % 60));
                var s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));
                this.m = m;
                this.s = s
            }
        },
        mounted() {
            this.getResult();
        }
    };
</script>
<style lang='less' scoped>
    .testresult {
        max-width: 1920px;
        min-width: 1200px;
        margin: 0 auto;

        /deep/ .el-breadcrumb {
            color: #333;
            margin: 49px 0 32px;
        }

        .resultbg {
            height: 82px;
            width: 100%;
            background-image: url("../../assets/images/active/result.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
        }

        .resultwrap {

            margin-bottom: 130px;
            min-height: 1038px;
            background: #fff;
            border-radius: 0 0 10px 10px;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            padding: 0 59px;

            .answerInfo {
                margin-top: 48px;
                display: flex;
                justify-content: space-between;

                .count,
                .time,
                .score {
                    width: 337px;
                    height: 150px;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    box-sizing: border-box;
                    overflow: hidden;

                    .item_l {
                        font-size: 16px;
                        color: #fff;
                        writing-mode: horizontal-tb;
                        -webkit-writing-mode: vertical-rl;
                        width: 80px;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                    }

                    .item_r {
                        height: 100%;
                        width: 257px;
                        display: flex;
                        align-items: center;
                        justify-content: space-around;

                        div {
                            font-size: 60px;
                            font-weight: bold;

                            span {
                                font-size: 18px;
                                font-weight: bold;
                                // margin-left: 23px;
                            }
                        }
                    }

                }

                .count {
                    .item_l {
                        background-color: #FB7B4A;
                    }

                    .item_r {
                        background-color: #FFF2ED;
                        color: #FB7B4A;
                    }
                }

                .time {

                    .item_l {
                        background-color: #00ADF3;
                    }

                    .item_r {
                        background-color: #E5F7FE;
                        color: #00ADF3;
                    }
                }

                .score {
                    .item_l {
                        background-color: #FDB137;
                    }

                    .item_r {
                        background-color: #FFF7EB;
                        color: #FDB137;
                    }
                }
            }

            .power {
                margin-top: 50px;
                width: 100%;
                height: 74px;
                background-image: url("../../assets/images/active/power.png");
                background-size: 100% 100%;
                background-repeat: no-repeat;

                span {
                    display: inline-block;
                    margin: 16px 147px;
                    font-size: 18px;
                    color: #508EF9;
                }
            }

            .detailInfo {
                height: 153px;
                border: 1px solid #D8D8D8;
                background-color: #F5F9FF;
                border-radius: 5px;
                margin-top: 35px;
                margin-bottom: 32px;
                display: flex;
                align-items: center;
                justify-content: space-around;

                .detailItem {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    span:nth-child(1) {
                        font-size: 16px;
                        color: #333;
                    }

                    span:nth-child(2) {
                        font-size: 34px;
                        color: #508EF9;
                    }
                }
            }

            .myTable {
                border: 1px solid #C7C7C7;
                margin-bottom: 58px;

                .isRight {
                    width: 16px;
                    height: 16px;
                    margin-left: 13px;
                    vertical-align: middle;
                }
            }
        }
    }
</style>